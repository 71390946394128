/**
 * @module root/AppShell/QueryPages
 * @summary This module exports the QueryPages component
 */
import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import pagesConfig from 'config/pagesConfig'
import Loadable from 'react-loadable'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import Loader from '@zeliot/common/ui/Loader'
import getLoginId from '@zeliot/common/utils/getLoginId'
import { SharedSnackbarProvider } from '@zeliot/common/shared/SharedSnackbar/SharedSnackbar.context'
import { DownloadProgressDialogProvider } from '@zeliot/common/shared/DownloadProgressDialog/DownloadProgressDialog.context'
import { setItem, getItem } from '../../../../storage.js'

/**
 * @summary The default Material UI theme to be used
 */
const defaultTheme = {
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#e53935'
    },
    secondary: {
      main: '#0091ea'
    }
  }
}

/**
 * @summary Lazy loaded Appshell component
 */
const AsyncAppShell = Loadable({
  loader: () => import('./AppShell.jsx'),
  loading: () => <Loader fullscreen={true} />
})

const GET_USER_CONFIGURATION = gql`
  query($loginId: Int!) {
    userConfiguration {
      plan
      config {
        configuration
        theme
      }
    }

    clientDetail(loginId: $loginId) {
      lat
      long
      isERP
    }

    getAllClientAds(clientLoginId: $loginId) {
      imageURL
      redirectURL
      placeOfImage
    }
  }
`

/**
 * @function
 * @param {object} props React props passed to this component
 * @summary This component fetches the pages for a user and passesthem to `AppShell` component
 */
const palette = {
  palette: {
    primary: {
      main: '#424242'
    },
    secondary: {
      main: '#ED2541'
    }
  }
}
const QueryPages = props => (
  <Query query={GET_USER_CONFIGURATION} variables={{ loginId: getLoginId() }}>
    {({ loading, error, data }) => {
      if (loading) return <Loader fullscreen={true} />
      if (error) return <div>Oops</div>

      const pages = data.userConfiguration.plan.features.map(
        feat => pagesConfig[feat.key]
      )
      if (pages.length === 0) {
        pages.push(pagesConfig.MANAGE_ACCOUNT)
      }

      const plan = data.userConfiguration.plan.name
      const { isERP } = data.clientDetail
      setItem('plan', plan, 'PERSISTENT')
      setItem('isERP', isERP, 'PERSISTENT')

      // For other configuration data use: data['userConfiguration']['config']['configuration']

      return (
        <MuiThemeProvider
          theme={createMuiTheme({
            ...defaultTheme,
            ...palette
          })}
        >
          <SharedSnackbarProvider>
            <DownloadProgressDialogProvider>
              <AsyncAppShell pages={pages} {...props} />
            </DownloadProgressDialogProvider>
          </SharedSnackbarProvider>
        </MuiThemeProvider>
      )
    }}
  </Query>
)

export default QueryPages
