/**
 * Entry point file for react components
 * @module index
 */

import React from 'react'
import ReactDOM from 'react-dom'
import gql from 'graphql-tag'
import Helmet from 'react-helmet/es/Helmet'
import { Query, ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'
import Root from '@zeliot/common/root'
import { AuthProvider, AuthConsumer } from '@zeliot/common/auth'
import getApolloClient from '@zeliot/common/apollo'
import Loader from '@zeliot/common/ui/Loader'
import DomainConfigError from '@zeliot/common/root/DomainConfigError'
import { checkTokenAuth } from './utils'
import './index.css'
import Favicon from './assets/topLogo.svg'

checkTokenAuth()

/**
 * @summary Query for fetching domain configuration
 */
const GET_DOMAIN_CONFIG = gql`
  query($domain: String!) {
    domainConfiguration(domain: $domain) {
      header {
        title
        shortcutIcon
      }
      page {
        background
        title
        subtitle
        logo
        navbarConfig {
          logo
          title
        }
      }
      customPage
      customPageId
    }
  }
`

/**
 * The main root component which ReactDOM mounts into a div. This component includes a
 * few top-level HOCs to configure authentication, routing, Apollo client setup and
 * fetching initial domainConfiguration
 * @summary Index Component
 */
function IndexComponent() {
  return (
    <AuthProvider>
      <AuthConsumer>
        {({ logout }) => (
          <BrowserRouter>
            <ApolloProvider client={getApolloClient({ logout })}>
              <Query
                query={GET_DOMAIN_CONFIG}
                variables={{ domain: window.location.host }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Loader fullscreen={true} />
                  if (error) return <DomainConfigError />

                  return (
                    <React.Fragment>
                      <Helmet>
                        {/* <title>{data.domainConfiguration.header.title}</title> */}
                        <title>MY EICHER</title>
                        <link
                          rel="icon"
                          type="image/png"
                          href="https://www.myeicher.in/assets/img/Eicher_MyEicher_AppLogo.png"
                        />
                      </Helmet>

                      <Root
                        page={data.domainConfiguration.page}
                        customPage={data.domainConfiguration.customPage}
                        customPageId={data.domainConfiguration.customPageId}
                      />
                    </React.Fragment>
                  )
                }}
              </Query>
            </ApolloProvider>
          </BrowserRouter>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

ReactDOM.render(<IndexComponent />, document.getElementById('root'))
